/**
 * @file
 * A JavaScript file for the theme.
 *
 * In order for this JavaScript to be loaded on pages, see the instructions in
 * the README.txt next to this file.
 */

/* globals Drupal: true */

// JavaScript should be made compatible with libraries other than jQuery by
// wrapping it with an "anonymous closure". See:
// - https://drupal.org/node/1446420
// - http://www.adequatelygood.com/2010/3/JavaScript-Module-Pattern-In-Depth
(function ($, Drupal) {

  'use strict';

  // Search box/header icons holder.
  let searchFormBlock;
  let headerIconsBlock;

  // DOM Ready.
  $(document).ready(function () {
    // Get the search box.
    if ($('#block-search-api-page-search').length > 0) {
      searchFormBlock = $('#block-search-api-page-search');
    }
    // Get the search box.
    if ($('#block-search-api-page-site-search').length > 0) {
      searchFormBlock = $('#block-search-api-page-site-search');
    }
    // if($(".view-grouping").has("div.department-23")) {
    //   //$( "div:has(p)" ).addClass( "test" );
    //   $(".view-grouping").has("div.department-23").addClass( "department-23" );
    // }

    // if($(".view-grouping").has("div.department-22")) {
    //   //$( "div:has(p)" ).addClass( "test" );
    //   $(".view-grouping").has("div.department-22").addClass( "department-22" );
    // }

    // if($(".view-grouping").has("div.department-24")) {
    //   //$( "div:has(p)" ).addClass( "test" );
    //   $(".view-grouping").has("div.department-24").addClass( "department-24" );
    // }

    // Get the header icons block.
    if ($('.header-icons-block').length > 0) {
      headerIconsBlock = $('.header-icons-block');
    }

    // Back to top helper.
    $(window).on('scroll', function () {
      if ($(this).scrollTop() > 100) {
        $('.back-to-top').addClass('show');
      }
      else {
        $('.back-to-top').removeClass('show');
      }
    });


    $('.back-to-top').on('click touchend', function (event) {
      event.preventDefault();
      $('html, body').animate({ scrollTop: 0 }, 200);
      $('.back-to-top').blur();
      return false;
    });

    // Background colors for ctas.
    if ($('#block-views-homepage-ctas-block').length > 0) {
      $('#block-views-homepage-ctas-block')
        .find('.views-field-field-resource-color')
        .find('.overlay')
        .each(function() {
          $(this).css('background-color', $(this).data('color'));
        });

      $('#block-views-homepage-ctas-block')
        .find('.views-fieldset')
        .on('mouseenter', function () {
          $(this)
            .find('.views-field-field-resource-color')
            .addClass('hover');
        })
        .on('mouseleave', function () {
          $(this)
            .find('.views-field-field-resource-color')
            .removeClass('hover');
        });
    }

    // Background colors for fact blocks.
    if ($('.view-fact-blocks').length > 0) {
      $('.view-fact-blocks')
        .find('.fact')
        .each(function() {
          $(this).css('background-color', $(this).data('color'));
        });
    }

    // Mobile table workaround



    $( ".view-embedded-resources").find("table").wrap( "<div class='table_wrapper'></div>" );
  });

  // Source https://developer.mozilla.org/en-US/docs/Web/Events/resize
  // requestAnimationFrame
  let optimizedResize = (function() {
    let callbacks = [],
      running = false;

      // fired on resize event
      function resize() {
        if (!running) {
          running = true;

          if (window.requestAnimationFrame) {
            window.requestAnimationFrame(runCallbacks);
          } else {
            setTimeout(runCallbacks, 66);
          }
        }
      }

      // run the actual callbacks
      function runCallbacks() {
        callbacks.forEach(function(callback) {
          callback();
        });

        running = false;
      }

      // adds callback to loop
      function addCallback(callback) {
        if (callback) {
          callbacks.push(callback);
        }
      }

      return {
        // public method to add additional callback
        add: function(callback) {
          if (!callbacks.length) {
            window.addEventListener('resize', resize);
          }

          addCallback(callback);
        }
      };
  }());

  // To understand behaviors, see https://drupal.org/node/756722#behaviors
  Drupal.behaviors.cnsopb = {
    // attach: function(context, settings) {
    attach: function() {

      // Cause apparently the attachment doesn't wait for the DOM to be ready.
      $(document).ready(function () {

        if ( $("#block-views-resource-types-block-1 .view-grouping .view-grouping-header.nested-accordion").length) {
          $("#block-views-resource-types-block-1 .view-grouping .view-grouping-header").removeClass('nested-accordion');
        }
        // Open 'external' links in new tab.
        $('a[rel~="external"]').attr('target', '_blank');

        // Hack for Search Icon in background of button... FA, wtf!
        $('.block-search-api-page')
          .find('.background')
          .remove();
        if($('.block-search-api-page #edit-actions').length === 0) {
          $('.block-search-api-page')
            .find('.form-submit')
            .before('<div id="edit-actions"></div>');
        }
        $('.block-search-api-page')
          .find('#edit-actions')
          .prepend('<div class="background"><i class="far fa-search fa-fw"></i></div>');
          
        $('.block-search-api-page')
          .find('input[type="submit"]')
          .on('focus mouseover', function () {
            $('.block-search-api-page')
              .find('#edit-actions')
              .addClass('hover');
          });

        $('.block-search-api-page')
          .find('input[type="submit"]')
          .on('blur mouseout', function () {
            $('.block-search-api-page')
              .find('#edit-actions')
              .removeClass('hover');
          });

        // Breadcrumb hacks... FA, wtf!
        if ($('.breadcrumb').length > 0) {
          let breadcrumb = $('.breadcrumb').html();
          breadcrumb = breadcrumb
            .split(' &gt; ')
            .join(' <i class="far fa-angle-right fa-fw"></i> ');
          $('.breadcrumb').html(breadcrumb);
        }

        // Menu hacks... FA, wtf!
        if ($('.sf-sub-indicator').length > 0) {
          $('.sf-depth-1')
            .find('.sf-sub-indicator')
            .html('<i class="fas fa-angle-down fa-fw"></i>');
          $('.sf-depth-2')
            .find('.sf-sub-indicator')
            .html('<i class="fas fa-angle-right fa-fw"></i>');
        }

        // Header Icons hacks... FA, wtf!
        if ($('.header-icons-block').length > 0) {
          $('.header-icons-block')
            .find('.header-icons--media-link')
            .find('.fa-newspaper')
            .remove();
          $('.header-icons-block')
            .find('.header-icons--media-link')
            .prepend('<i class="far fa-newspaper fa-lg fa-fw"></i>');

          $('.header-icons-block')
            .find('.header-icons--contact-us')
            .find('.fa-envelope')
            .remove();
          $('.header-icons-block')
            .find('.header-icons--contact-us')
            .prepend('<i class="far fa-envelope fa-lg fa-fw"></i>');

          $('.header-icons-block')
            .find('.header-icons--twitter')
            .find('.fa-twitter')
            .remove();
          $('.header-icons-block')
            .find('.header-icons--twitter')
            .prepend('<i class="fab fa-twitter fa-lg fa-fw"></i>');
        }

        // Pager hacks... FA, wtf!
        if ($('.pager').length > 0) {
          if ($('.pager-first').find('a').length > 0) {
            let pagerFirst = $('.pager-first').find('a').html();
            pagerFirst = pagerFirst
              .split('« ')
              .join('<i class="fal fa-arrow-to-left fa-fw"></i>');
            $('.pager-first').find('a').html(pagerFirst);
          }

          if ($('.pager-previous').find('a').length > 0) {
            let pagerPrevious = $('.pager-previous').find('a').html();
            pagerPrevious = pagerPrevious
              .split('‹ ')
              .join('<i class="fal fa-arrow-from-right fa-fw"></i>');
            $('.pager-previous').find('a').html(pagerPrevious);
          }

          if ($('.pager-next').find('a').length > 0) {
            let pagerNext = $('.pager-next').find('a').html();
            pagerNext = pagerNext
              .split(' ›')
              .join('<i class="fal fa-arrow-from-left fa-fw"></i>');
            $('.pager-next').find('a').html(pagerNext);
          }

          if ($('.pager-last').find('a').length > 0) {
            let pagerLast = $('.pager-last').find('a').html();
            pagerLast = pagerLast
              .split(' »')
              .join('<i class="fal fa-arrow-to-right fa-fw"></i>');
            $('.pager-last').find('a').html(pagerLast);
          }
        }

        // Homepage hacks... FA, wtf!
        if ($('#block-views-news-summary-block').length > 0) {
          $('#block-views-news-summary-block')
            .find('.all-media')
            .find('a')
            .find('.fa-arrow-circle-right')
            .remove();
          $('#block-views-news-summary-block')
            .find('.all-media')
            .find('a')
            .append('<i class="far fa-arrow-circle-right fa-fw"></i>');

          $('#block-views-recent-resources-block')
            .find('.library')
            .find('a')
            .find('.fa-arrow-circle-right')
            .remove();
          $('#block-views-recent-resources-block')
            .find('.library')
            .find('a')
            .append('<i class="far fa-arrow-circle-right fa-fw"></i>');
        }

        // Search Resource Library fields... FA, wtf!
        if ($('.resource-library-search').length > 0) {
          $('.form-type-select')
            .find('.fa-angle-down')
            .remove();
          $('.form-type-select')
            .append('<i class="far fa-angle-down fa-fw"></i>');

          $('.date-padding')
            .find('.form-type-textfield')
            .find('.fa-calendar-alt')
            .remove();
          $('.date-padding')
            .find('.form-type-textfield')
            .append('<i class="far fa-calendar-alt fa-fw"></i>');
        }

        // Search Licences fields... FA, wtf!
        if ($('#cnsopb-license-map-filter-form').length > 0) {
          $('.form-type-select')
            .find('.fa-angle-down')
            .remove();
          $('.form-type-select')
            .find('.field-wrapper')
            .append('<i class="far fa-angle-down fa-fw"></i>');

          $('.date-padding')
            .find('.form-type-textfield')
            .find('.fa-calendar-alt')
            .remove();
          $('.date-padding')
            .find('.form-type-textfield')
            .append('<i class="far fa-calendar-alt fa-fw"></i>');
        }

        // Search Team Member fields... FA, wtf!
        if ($('.team-member-search').length > 0) {
          $('.form-type-select')
            .find('.fa-angle-down')
            .remove();
          $('.form-type-select')
            .append('<i class="far fa-angle-down fa-fw"></i>');
          $('.ui-accordion-header-icon')
            .find('.far')
            .remove();
          $('.ui-accordion-header-icon')
            .append('<i class="far fa-angle-down fa-fw"></i>')
            .append('<i class="far fa-angle-up fa-fw"></i>');
        }

        // Emebedded Resources fields... FA, wtf!
        if ($('.embeded-resources').length > 0) {
          // $('.form-type-select')
          //   .find('.fa-angle-down')
          //   .remove();
          // $('.form-type-select')
          //   .append('<i class="far fa-angle-down fa-fw"></i>');
          $('.ui-accordion-header-icon')
            .find('.far')
            .remove();
          $('.ui-accordion-header-icon')
            .append('<i class="far fa-angle-down fa-fw"></i>')
            .append('<i class="far fa-angle-up fa-fw"></i>');
        }

        // Resources Keys... FA, wtf!
        if ($('.resource-type-icon').length > 0) {
          $('.resource-type-icon').each(function () {
            let color = $(this).data('color');
            $(this).css('background-color', color);
          });
        }

        // Resource Icons hacks... FA, wtf! (not really, there's just no alter
        // that I can use to replace the image at the time of render without
        // taking over the whole theme function)
        if ($('.file-icon').length > 0) {
          // Build an array of known icons.
          let fileIcon = {
            '/modules/file/icons/application-octet-stream.png': 'file-alt',
            '/modules/file/icons/application-pdf.png': 'file-pdf',
            '/modules/file/icons/application-x-executable.png': 'file-archive',
            '/modules/file/icons/audio-x-generic.png': 'file-audio',
            '/modules/file/icons/image-x-generic.png': 'file-image',
            '/modules/file/icons/package-x-generic.png': 'file-archive',
            '/modules/file/icons/text-html.png': 'file-code',
            '/modules/file/icons/text-plain.png': 'file-alt',
            '/modules/file/icons/text-x-generic.png': 'file',
            '/modules/file/icons/text-x-script.png': 'file-code',
            '/modules/file/icons/video-x-generic.png': 'file-video',
            '/modules/file/icons/x-office-document.png': 'file-word',
            '/modules/file/icons/x-office-presentation.png': 'file-powerpoint',
            '/modules/file/icons/x-office-spreadsheet.png': 'file-excel',
          };

          // Loop through the icons.
          $('.file-icon').each(function () {
            // Get the image path.
            let originalImage = $(this).attr('src');

            // If we have a replacement defined, update it.
            if (!!fileIcon[originalImage]) {
              $(this).replaceWith('<i class="fal fa-' + fileIcon[originalImage] + '"></i>');
            }
            else {
              // Otherwise, fallback to the default for brand consistency.
              $(this).replaceWith('<i class="fal fa-file-alt"></i>');
            }
          });

          // Force file to open in new window.
          $('.file').find('a').attr('target', '_blank');
        }

        // Mobile menu hamburger insert.
        let superfishToggle = function () {
          if ($(window).width() < 1024) {
            $('#superfish-1-toggle')
              .find('.hamburger')
              .remove();
            $('#superfish-1-toggle')
              .append('<span class="hamburger">&nbsp;</span>');
          }
        };
        superfishToggle();

        // Mobile Search/icon bar.
        let mobileSearchIconBar = function() {
          if (!!searchFormBlock &&
            !!headerIconsBlock) {
            if ($(window).width() < 768) {
              if ($('#superfish-1-toggle').length > 0) {
                searchFormBlock.detach();
                $('#superfish-1-accordion')
                  .find('.search-bar')
                  .remove();
                $('#superfish-1-accordion')
                  .prepend('<li class="search-bar"></li>');
                searchFormBlock.appendTo('.search-bar');

                headerIconsBlock.detach();
                headerIconsBlock.appendTo('.sf-accordion-toggle');
              }
            }
            else if ($(window).width() >= 768) {
              searchFormBlock.detach();
              searchFormBlock.appendTo('.region-header');

              headerIconsBlock.detach();
              headerIconsBlock.appendTo('.region-header');
            }
          }
        };
        mobileSearchIconBar();

        // Resize event.
        optimizedResize.add(function() {
          superfishToggle();
          mobileSearchIconBar();
        });

      });
    }
  };



})(jQuery, Drupal);
